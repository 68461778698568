<template>
  <div>
    <draggable v-model="model" item-key="name" class="list-group" handle=".handle" ghost-class="ghost">
      <template #item="{ element, index }">
        <div class="list-group-item container">
          <div class="row">
            <div class="col-1 d-inline-flex align-items-center handle pointer">
              <fa icon="grip"></fa>
            </div>
            <div class="col-7 d-inline-flex align-items-center">
              {{ element.name }}
            </div>
            <div class="col-3">
              <input type="number" style="width: 100%" v-model="element.duration" min="1" max="600"
                @change="element.duration = clamp(element.duration, 1, 600)" />
            </div>
            <div class="col-1 d-inline-flex align-items-center pointer" @click="removeAt(index)">
              <fa icon="xmark"></fa>
            </div>
          </div>
        </div>
      </template>
    </draggable>
  </div>
</template>

<script setup lang="ts">
import type { TimelineData } from '../library/Constants';
import draggable from "vuedraggable";

import { computed } from "vue";

const props = defineProps<{ modelValue: TimelineData[] }>();

const emit = defineEmits<{
  (event: "update:modelValue", value: TimelineData[]): void;
}>();

const model = computed({
  get: () => {
    return props.modelValue;
  },
  set: (value) => {
    emit("update:modelValue", value);
  },
});

const handleChange = () => {
  console.log("Change.");
};

const removeAt = (index: number) => {
  model.value.splice(index, 1);
};

const clamp = (value: number, min: number, max: number) => {
  if (value < min) value = min;
  if (value > max) value = max;
  return value;
};
</script>

<style scoped>
.list-group-item {
  user-select: none;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.pointer {
  cursor: pointer;
}
</style>
