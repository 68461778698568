
<template>
  <div class="container">
    <!-- <div class="row">
      <div class="col-8">
        <textarea class="form-control" v-model="message" rows="20"></textarea
        ><br />
        <button class="btn btn-primary m-1" @click="sendRawCommand">
          Send
        </button>
      </div>
      <div class="col-4"> -->

    <div class="card mb-2">
      <div class="card-body">
        <h5 class="card-title">Target Headset</h5>
        <select class="form-select" v-model="activeHeadset" aria-label="Default select example">
          <option value="" selected>All Headsets</option>
          <option v-for="[key, value] in $refrain?.state.clients" :value="key">{{ key }}</option>
        </select>
      </div>
    </div>

    <div class="card mb-2">
      <div class="card-body">
        <h5 class="card-title">Control</h5>
        <div class="btn-group w-100">
          <button class="btn btn-outline-primary" @click="sendAction(ActionCommand.PauseResume)">
            Pause/Resume
          </button>
          <button class="btn btn-outline-primary" @click="sendAction(ActionCommand.Stop)">
            Stop
          </button>
          <button class="btn btn-outline-primary" @click="sendAction(ActionCommand.WaitingRoom)">
            Waiting Room
          </button>
          <button class="btn btn-outline-primary" @click="sendAction(ActionCommand.RecenterHeadset)">
            Recenter Headset
          </button>
        </div>
      </div>
    </div>

    <div class="card mb-2">
      <div class="card-body">
        <h5 class="card-title">Tableau Scene</h5>
        <div class="input-group">
          <button class="btn btn-primary " @click="
            sendAction(ActionCommand.LoadTableauScene, [selectedTableauScene])
          ">
            Load Scene
          </button>
          <select v-model="selectedTableauScene" class="form-select">
            <option v-for="(index, scene) in TableauScene" :value="index">
              {{ scene }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="card mb-2">
      <div class="card-body">
        <h5 class="card-title">Tableau Audio</h5>
        <div class="input-group">
          <button class="btn btn-primary " @click="
            sendAction(ActionCommand.LoadTableauAudio, [selectedTableauAudio])
          ">
            Load Audio
          </button>
          <select v-model="selectedTableauAudio" class="form-select">
            <option v-for="(index, audio) in TableauAudio" :value="index">
              {{ audio }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="card mb-2">
      <div class="card-body">
        <h5 class="card-title">Time</h5>
        <div class="btn-group w-100">
          <button class="btn btn-outline-primary" @click="sendAction(ActionCommand.AddTime, [60])">
            Add 60 Seconds
          </button>
          <button class="btn btn-outline-primary" @click="sendAction(ActionCommand.AddTime, [300])">
            Add 300 Seconds
          </button>
        </div>
      </div>
    </div>

    <div class="card mb-2">
      <div class="card-body">
        <h5 class="card-title">Seek To Time</h5>
        <div class="input-group">
          <button class="btn btn-outline-primary" @click="sendAction(ActionCommand.SeekToTime, [seekTime])">
            Seek To Time
          </button>
          <input class="form-control" type="number" v-model="seekTime" />
        </div>
      </div>
    </div>

    <div class="card mb-2">
      <div class="card-body">
        <h5 class="card-title">Audio Cues</h5>
        <div class="btn-group w-100">
          <button class="btn btn-outline-primary" @click="sendAction(ActionCommand.PlayAudioCue, [AudioCue.CueA])">
            Play Cue A</button><button class="btn btn-outline-primary"
            @click="sendAction(ActionCommand.PlayAudioCue, [AudioCue.CueB])">
            Play Cue B
          </button>
          <button class="btn btn-outline-primary" @click="sendAction(ActionCommand.PlayAudioCue, [AudioCue.CueC])">
            Play Cue C
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- </div>
  </div> -->
</template >

<script setup lang="ts">
import type { ActionCommandData, Refrain } from "../services/Refrain";

import { ref, inject, watch } from "vue";

import {
  TableauAudio,
  TableauScene,
  AudioCue,
  ActionCommand,
} from "../library/Constants";

//TODO(SJG): Global provide instead?
const $refrain: Refrain | undefined = inject<Refrain>("$refrain");

const activeHeadset = ref<string>("");
const selectedTableauScene = ref(100);
const selectedTableauAudio = ref(100);
const seekTime = ref(0);

watch(() => $refrain?.state.clients, (clients) => {
  if (!clients?.has(activeHeadset.value)) {
    console.log("Removing stale client.");
    activeHeadset.value = "";
  }
}, { deep: true });

const sendAction = (action: number, parameters: Array<number> = []) => {
  let commandData: ActionCommandData = {
    type: "action",
    action: action,
    parameters: parameters,
  };

  // Inject headset id if available.
  if (activeHeadset.value) commandData.target = activeHeadset.value;

  sendCommandData(commandData);
};

const sendCommandData = (commandData: object) => {
  try {
    let commandJson = JSON.stringify(commandData);
    sendCommandJson(commandJson);
  } catch (e: unknown) {
    if (e instanceof Error) console.log("CommandData: Invalid JSON: " + e.message);
  }
};

const sendCommandJson = (commandJson: string) => {
  $refrain?.send(commandJson);
};

// let command = JSON.stringify({
//   type: "timeline",
//   timelineData: {
//     audio: [
//       {
//         id: 1,
//         startTime: 2,
//         endTime: 3,
//       },
//     ],
//     scenes: [
//       {
//         id: 1,
//         startTime: 2,
//         endTime: 3,
//       },
//     ],
//   },
// });

/*
export default {
  data() {
    return {
      TableauAudio,
      TableauScene,
      AudioCue,
      ActionCommand,
      message: command,
      selectedTableauScene: 100,
      selectedTableauAudio: 100,
      ws: undefined,
    };
  },
  methods: {
    // sendRawCommand() {
    //   try {
    //     let data = JSON.parse(this.message);
    //     this.message = JSON.stringify(data, null, 2);
    //     let messageJson = this.message;
    //     console.log(messageJson);

    //     this.sendCommandJson(messageJson);
    //   } catch (e) {
    //     console.log("RawCommand: Invalid JSON: " + e.message);
    //   }
    // },
  },
};
*/
</script>