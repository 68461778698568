<template>
  <div class="modal fade" id="timelineSceneSelect" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="timelineSceneSelectLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="timelineSceneSelectLabel">
            Choose Scene
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-center container">
          <div class="row">
            <div v-for="[key, value] in TableauSceneData" :value="value" class="col-4">
              <div class="tile my-1 position-relative" :style="{ backgroundImage: 'url(' + value.thumbnail + ')' }">
                <button class="position-absolute bottom-0 end-0 m-1 btn btn-primary" @click="handleClick(key, value)"
                  data-bs-dismiss="modal">
                  <fa icon="plus"></fa>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SceneTemplateData } from "../library/Constants";
import { TableauSceneData } from "../library/Constants";

const emit = defineEmits<{
  (event: "add", data: any): void;
}>();

const handleClick = (key: number, value: SceneTemplateData) => {
  let data = { id: key, ...value };
  emit("add", data);
};
</script>

<style scoped>
.tile {
  background: gray;
  border: 1px solid black;
  border-radius: 4px;
  padding-bottom: 100%;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
</style>