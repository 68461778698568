<template>
  <div>
    <div class="segments">
      <div v-for="(value, index) in list" :value="index" class="segment alert alert-primary"
        :style="{ width: getWidthForDuration(value.duration) + '%' }">
        {{ data.get(value.id).name }}
      </div>
    </div>
  </div>
</template>


<script setup lang="ts">

const props = defineProps<{
  list: any;
  totalDuration: number;
  data: any;
}>();

const getWidthForDuration = (duration: number) => {
  return (duration / props.totalDuration) * 100;
};
</script>

<style scoped>
.segments {
  height: 64px;
  width: 100%;
  background: #efefef;
  overflow: hidden;
}

.segment {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
}
</style>
