<template>
  <div class="modal fade" id="timelineAudioSelect" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
    aria-labelledby="timelineAudioSelectLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="timelineAudioSelectLabel">
            Choose audio
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body container">
          <ul class="list-group">
            <li v-for="[key, value] in TableauAudioData" class="
                list-group-item
                d-flex
                justify-content-between
                align-items-center
              ">
              <span>{{ value.name }}</span>
              <span>
                <button class="btn btn-primary mx-1" @click.stop="handleSampleClick(key, value)">
                  <fa icon="play"></fa>
                </button>
                <button class="btn btn-primary" @click.stop="handleAddClick(key, value)" data-bs-dismiss="modal">
                  <fa icon="plus"></fa>
                </button>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { AudioTemplateData } from "../library/Constants";
import { TableauAudioData } from "../library/Constants";

const audio = new Audio();
const emit = defineEmits(["add"]);

const handleAddClick = (key: number, value: AudioTemplateData) => {
  audio.pause();
  emit("add", { id: key, ...value });
};

const handleSampleClick = (key: number, value: AudioTemplateData) => {
  if (audio.src == value.clip.toString()) {
    audio.src = "";
    audio.pause();
  } else {
    audio.src = value.clip.toString();
    audio.play();
  }
};
</script>