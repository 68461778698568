<template>
  <div class="container">
    <div class="row g-2">
      <div class="col-lg-4 col-md-12">
        <div class="card overflow-auto mb-2">
          <ul class="list-group list-group-flush w-100">
            <li class="list-group-item bg-secondary text-light">
              Default Presets
            </li>
            <li v-for="(preset, index) in TableauPresets" class="
              list-group-item
              d-flex
              justify-content-between
              align-items-center
            " :class="{ active: isActivePreset(preset) }" @click="setActivePreset(preset)" role="button">
              {{ preset.name }}
            </li>
          </ul>
        </div>
        <div class="card overflow-auto">
          <ul class="list-group list-group-flush w-100">
            <li class="list-group-item bg-secondary text-light" v-show="$store?.data.presets.length">User Presets
            </li>
            <li v-for="(preset, index) in $store?.data.presets" class="
              list-group-item
              d-flex
              justify-content-between
              align-items-center
            " :class="{ active: isActivePreset(preset) }" @click="setActivePreset(preset)" role="button">
              {{ preset.name }}
            </li>
          </ul>
        </div>
      </div>
      <div class="col-lg-8 col-md-12">
        <div class="card mb-2">
          <div class="card-body">
            <TimelinePreview :list="activePreset.audio" :data="TableauAudioData" :totalDuration="totalDuration" />
            <TimelinePreview :list="activePreset.scenes" :data="TableauSceneData" :totalDuration="totalDuration"
              class="my-1" />
            <div class="float-end text-muted"><b>Total Duration:</b> {{ totalDuration }} minute(s)</div>
          </div>
        </div>
        <div class="card mb-2">
          <div class="card-body">

            <div class="input-group mt-2">
              <button type="button" class="btn btn-primary" @click="sendPreset">
                <fa icon="vr-cardboard"></fa>
                Send To Headset
              </button>
              <select class="form-select" v-model="activeHeadset">
                <option value="" selected>All Headsets</option>
                <option v-for="[key, value] in $refrain?.state.clients" :value="key">{{ key }}</option>
              </select>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PresetData, TimelineData } from "../library/Constants";
import type { TimelineCommandData, Refrain } from "../services/Refrain";
import type Store from "../library/Store";

import TimelinePreview from "./TimelinePreview.vue";

import {
  TableauPresets,
  TableauAudioData,
  TableauSceneData,
} from "../library/Constants";

import { toRaw, ref, computed, inject, watch } from "vue";

const $refrain: Refrain | undefined = inject("$refrain");
const $store: Store | undefined = inject("$store");

let presetTemplate: PresetData = { name: "", audio: [], scenes: [] };
const activePreset = ref(presetTemplate);
const activeHeadset = ref<string>("");

const totalAudioDuration = computed(() => {
  let audioDuration = 0;
  activePreset.value.audio.forEach((audio: TimelineData) => {
    audioDuration += (audio.duration);
  });
  return audioDuration;
});

const totalSceneDuration = computed(() => {
  let sceneDuration = 0;
  activePreset.value.scenes.forEach((scene: TimelineData) => {
    sceneDuration += (scene.duration);
  });
  return sceneDuration;
});

const totalDuration = computed(() => {
  let duration = Math.max(totalSceneDuration.value, totalAudioDuration.value);
  return duration;
});

watch(() => $refrain?.state.clients, (clients) => {
  if (!clients?.has(activeHeadset.value)) {
    console.log("Removing stale client.");
    activeHeadset.value = "";
  }
}, { deep: true });

const setActivePreset = (preset: PresetData) => {
  activePreset.value = preset;
}

const isActivePreset = (preset: PresetData) => {
  // Use toRaw to compare reactive proxy objects.
  return toRaw(activePreset.value) == toRaw(preset);
}

const sendPreset = () => {
  let timelineData: TimelineCommandData = {
    type: "timeline",
    timelineData: {
      audio: activePreset.value.audio,
      scenes: activePreset.value.scenes,
    },
  };

  // Inject headset id if available.
  if (activeHeadset.value) timelineData.target = activeHeadset.value;

  let timelineJson = JSON.stringify(timelineData);
  $refrain?.send(timelineJson);
};
</script>