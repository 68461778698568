<template>
  <div class="container">
    <div class="card mb-2">
      <div class="card-body">
        <h5 class="card-title">Credentials</h5>
        <div class="form-group">
          <label for="inputNamespace">Namespace</label>
          <input type="email" class="form-control" id="inputNamespace" placeholder="Enter Namespace" />
        </div>
        <div class="form-group">
          <label for="inputKey">Key</label>
          <input type="password" class="form-control" id="inputKey" placeholder="Enter Key" />
        </div>
        <button class="btn btn-primary btn-sm mt-2" @click="saveSettings">
          <fa icon="file-export"></fa>
          Save
        </button>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">Settings</h5>
        <button class="btn btn-primary btn-sm mt-2" @click="clearLocalData">
          <fa icon="ban"></fa>
          Clear Local Data
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { inject } from "vue";
import type Store from "../library/Store";

const $store: Store | undefined = inject("$store");

const saveSettings = () => { };

const clearLocalData = () => {
  $store?.clear();
};
</script>