<template>
  <div>
    Connected: {{ $refrain?.state.connected }}

    <h2>Clients</h2>
    <ul class="list-group">
      <li v-for="[key, value] in $refrain?.state.clients" class="list-group-item container">
        <div class="row">
          <div class="col-4"><b>Headset:</b> {{ key }}</div>
          <div class="col-4"><b>State:</b> {{ value.state }}</div>
          <div class="col-4"><b>Timestamp:</b> {{ value.timestamp }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import type { Refrain } from "../services/Refrain";
import { inject } from 'vue';
const $refrain: Refrain | undefined = inject('$refrain');
</script>