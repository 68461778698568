import type { PresetData } from '../library/Constants';
import type { App } from 'vue';

import { reactive, watch } from 'vue';

interface State {
    presets: PresetData[]
}

class Store {
    defaultState: State = {
        presets: []
    };

    data = reactive(this.defaultState);

    constructor() {
        this.reload();

        watch(this.data, (newValue, oldValue) => {
            console.log("Updating data store...");
            localStorage.setItem("data", JSON.stringify(this.data));
        });
    }

    install(app: App) {
        app.config.globalProperties.$store = this;
        app.provide("$store", this);
    }

    reload() {
        try {
            console.log("Restoring data...");
            let dataJson = localStorage.getItem("data");
            if (dataJson) {
                let data = JSON.parse(dataJson);
                this.data = reactive(data);
            }
        } catch (error) {
            console.log("Failed to restore data...");
            this.clear();
        }
    }

    clear() {
        console.log("Clearing data...");
        localStorage.clear();
        Object.assign(this.data, this.defaultState);
    }
}

export default Store;