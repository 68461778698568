import type { App } from 'vue';
import { reactive } from 'vue';

import type { TimelineData } from '../library/Constants';

const refreshTimeout: number = 5000;
const reconnectTimeout: number = 1000;
const clientTimeout: number = 30000;

interface Message {
    type: string
}
interface StatusMessage extends Message {
    id: string,
    state: string
}

export interface TimelineCommandData extends Message {
    target?: string;
    timelineData: { audio: TimelineData[], scenes: TimelineData[] }
}

export interface ActionCommandData extends Message {
    target?: string;
    action: number,
    parameters: number[]
}

interface ClientStatus {
    state: string,
    timestamp: number
}

export class Refrain extends EventTarget {

    ws: WebSocket | undefined;

    openEvent = new CustomEvent("open");
    closeEvent = new CustomEvent("close");

    state = reactive({
        connected: false,
        clients: new Map<string, ClientStatus>()
    });

    constructor() {
        super();

        let url = "wss://refrain-api-staging.j38.workers.dev/namespace/";
        let namespace = 1234;

        try {
            this.connect(url + namespace);
        } catch (e) {
            console.log(e);
        }
        this.refresh();
    }

    install(app: App) {
        app.provide('$refrain', this);
        app.config.globalProperties.$refrain = this;
    }

    connect(url: string) {

        if (this.ws != undefined) {
            this.ws.onopen = null;
            this.ws.onclose = null;
            this.ws.onmessage = null;
            this.ws.onerror = null;
        }

        this.ws = new WebSocket(url);

        this.ws.onopen = () => {
            console.log(`Connection open to Refrain API server: ${url}.`);
            this.state.connected = true;
            this.dispatchEvent(this.openEvent);
        };

        this.ws.onclose = () => {
            this.state.connected = false;
            this.dispatchEvent(this.closeEvent);

            console.log("Reconnecting...");
            setTimeout(() => { this.connect(url); }, reconnectTimeout)
        };

        this.ws.onerror = (e) => {
            console.log(e);
        }

        this.ws.onmessage = (event) => {
            let data = JSON.parse(event.data);
            let customEvent = new CustomEvent(data.type, { detail: data });

            this.dispatchEvent(customEvent);

            if (data.type == "system") {
                console.log("System message: " + data.message);
            }

            if (data.type == "status") {
                let statusMessage: StatusMessage = data as StatusMessage;
                console.log("Status message");
                if (statusMessage.id) {
                    this.state.clients.set(statusMessage.id, {
                        state: statusMessage.state,
                        timestamp: Date.now()
                    });
                }
            }
        };
    }

    refresh() {
        console.log("Refreshing...");

        // Clean up state clients.
        let now = Date.now();
        this.state.clients.forEach((value, key, map) => {
            if (now - value.timestamp > clientTimeout) map.delete(key);
        });

        // Schedule next refresh.
        setTimeout(() => {
            this.refresh();
        }, refreshTimeout);
    }

    send(message: string) {
        console.log(message);
        this.ws?.send(message);
    }
}
