
// Vue
import { createApp } from "vue";
import App from "./components/App.vue";

// Bootstrap
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
    faGrip,
    faXmark,
    faPlus,
    faPlay,
    faFileExport,
    faCompress,
    faVrCardboard,
    faBan
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faGrip,
    faXmark,
    faPlus,
    faPlay,
    faFileExport,
    faCompress,
    faVrCardboard,
    faBan
);

// Routes
import { createRouter, createWebHistory } from 'vue-router'

import Home from "./components/Home.vue";
import Presets from "./components/Presets.vue";
import Timeline from "./components/Timeline.vue";
import Controls from "./components/Controls.vue";
import Settings from "./components/Settings.vue";

const routes = [
    { path: '/', component: Home },
    { path: '/controls', component: Controls },
    { path: '/presets', component: Presets },
    { path: '/timeline', component: Timeline },
    { path: '/settings', component: Settings },
]

const router = createRouter({
    history: createWebHistory(),
    linkExactActiveClass: "active",
    routes,
})

// Store
import Store from "./library/Store"
const store = new Store();

// Refrain
import { Refrain } from "./services/Refrain";
const refrain = new Refrain();

// Create app.
createApp(App)
    .use(router)
    .use(refrain)
    .use(store)
    .component('fa', FontAwesomeIcon)
    .mount("#app");
