export const AudioCue = {
    CueA: 100,
    CueB: 200,
    CueC: 300,
} as const;

export const ActionCommand = {
    PauseResume: 100,
    Stop: 200,
    WaitingRoom: 300,
    LoadTableauScene: 400,
    LoadTableauAudio: 500,
    AddTime: 600,
    PlayAudioCue: 700,
    RecenterHeadset: 800,
    SeekToTime: 900
} as const;

export const TableauAudio = {
    VoiceOverA: 100,
    VoiceOverB: 200,
    SongA: 300,
    SongB: 400,
    Ambience: 500,
    Silence: 600,
} as const;

export const TableauScene = {
    SceneA: 100,
    SceneB: 200,
    SceneC: 300,
    SceneD: 400,
    SceneE: 500,
    SceneF: 600,
    SceneG: 700,
    SceneH: 800,
    SceneI: 900,
    SceneJ: 1000,
    SceneK: 1100,
    SceneL: 1200,
    SceneM: 1300,
    SceneN: 1400,
    SceneO: 1500,
} as const;

export const TableauPresets =
    [
        {
            "name": "Preset A",
            "audio": [
                { "id": 200, "duration": 6 },
                { "id": 100, "duration": 4 }
            ],
            "scenes": [
                { "id": 200, "duration": 2.5 },
                { "id": 100, "duration": 2.5 },
                { "id": 600, "duration": 2.5 },
                { "id": 300, "duration": 2.5 }
            ]
        },
        {
            "name": "Preset B",
            "audio": [
                { "id": 100, "duration": 4 },
                { "id": 200, "duration": 6 }
            ],
            "scenes": [
                { "id": 200, "duration": 2.5 },
                { "id": 600, "duration": 2.5 },
                { "id": 100, "duration": 2.5 },
                { "id": 300, "duration": 2.5 }
            ]
        }
    ];

export interface TimelineData {
    id: number;
    duration: number;
}

export interface PresetData {
    name: string,
    audio: TimelineData[];
    scenes: TimelineData[];
}

export interface TemplateData {
    name: string,
    duration: number,
}

export interface AudioTemplateData extends TemplateData {
    clip: URL
}

export interface SceneTemplateData extends TemplateData {
    thumbnail: URL
}

export const TableauAudioData = new Map<number, AudioTemplateData>([
    [TableauAudio.VoiceOverA,
    {
        name: "Voice-over A",
        duration: 1,
        clip: new URL("../assets/audioA.mp3", import.meta.url),
    }],
    [TableauAudio.VoiceOverB,
    {
        name: "Voice-over B",
        duration: 1,
        clip: new URL("../assets/audioB.mp3", import.meta.url),
    }],
    [TableauAudio.SongA,
    {
        name: "Song A",
        duration: 1,
        clip: new URL("../assets/audioC.mp3", import.meta.url),
    }],
    [TableauAudio.SongB,
    {
        name: "Song B",
        duration: 1,
        clip: new URL("../assets/audioD.mp3", import.meta.url),
    }],
    [TableauAudio.Ambience,
    {
        name: "Ambience",
        duration: 1,
        clip: new URL("../assets/audioE.mp3", import.meta.url),
    }],
    [TableauAudio.Silence,
    {
        name: "Silence",
        duration: 1,
        clip: new URL("../assets/audioF.mp3", import.meta.url),
    }],
]);

export const TableauSceneData = new Map<number, SceneTemplateData>([
    [TableauScene.SceneA,
    {
        name: "Scene A",
        duration: 1,
        thumbnail: new URL("../assets/sceneA.png", import.meta.url),
    }],
    [TableauScene.SceneB,
    {
        name: "Scene B",
        duration: 1,
        thumbnail: new URL("../assets/sceneB.png", import.meta.url),
    }],
    [TableauScene.SceneC,
    {
        name: "Scene C",
        duration: 1,
        thumbnail: new URL("../assets/sceneC.png", import.meta.url),
    }],
    [TableauScene.SceneD,
    {
        name: "Scene D",
        duration: 1,
        thumbnail: new URL("../assets/sceneD.png", import.meta.url),
    }],
    [TableauScene.SceneE,
    {
        name: "Scene E",
        duration: 1,
        thumbnail: new URL("../assets/sceneE.png", import.meta.url),
    }],
    [TableauScene.SceneF,
    {
        name: "Scene F",
        duration: 1,
        thumbnail: new URL("../assets/sceneF.png", import.meta.url),
    }],
    [TableauScene.SceneG,
    {
        name: "Scene G",
        duration: 1,
        thumbnail: new URL("../assets/sceneD.png", import.meta.url),
    }],
    [TableauScene.SceneH,
    {
        name: "Scene H",
        duration: 1,
        thumbnail: new URL("../assets/sceneE.png", import.meta.url),
    }],
    [TableauScene.SceneI,
    {
        name: "Scene I",
        duration: 1,
        thumbnail: new URL("../assets/sceneF.png", import.meta.url),
    }],
    [TableauScene.SceneJ,
    {
        name: "Scene G",
        duration: 1,
        thumbnail: new URL("../assets/sceneD.png", import.meta.url),
    }],
    [TableauScene.SceneK,
    {
        name: "Scene H",
        duration: 1,
        thumbnail: new URL("../assets/sceneE.png", import.meta.url),
    }],
    [TableauScene.SceneL,
    {
        name: "Scene I",
        duration: 1,
        thumbnail: new URL("../assets/sceneF.png", import.meta.url),
    }],
    [TableauScene.SceneM,
    {
        name: "Scene G",
        duration: 1,
        thumbnail: new URL("../assets/sceneD.png", import.meta.url),
    }],
    [TableauScene.SceneN,
    {
        name: "Scene H",
        duration: 1,
        thumbnail: new URL("../assets/sceneE.png", import.meta.url),
    }],
    [TableauScene.SceneO,
    {
        name: "Scene I",
        duration: 1,
        thumbnail: new URL("../assets/sceneF.png", import.meta.url),
    }],
]);

