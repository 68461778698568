<template>
  <div class="container-fluid p-0">
    <nav class="navbar navbar-expand-md navbar-dark bg-dark mb-2">
      <div class="container">
        <router-link class="navbar-brand" to="/">Rocket</router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav">
            <!-- <router-link class="nav-link" to="/presets">Presets</router-link>
            <router-link class="nav-link" to="/timeline">Timeline</router-link> -->
            <router-link class="nav-link" to="/controls">Controls</router-link>
            <!-- <router-link class="nav-link" to="/settings">Settings</router-link> -->
          </div>
        </div>
      </div>
    </nav>

    <router-view v-slot="{ Component, route }">
      <transition name="fade">
        <component :is="Component" :key="route.path" />
      </transition>
    </router-view>

  </div>
</template >

<script setup lang="ts">
</script>