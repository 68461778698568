
<template>
  <div class="container">

    <div class="card mb-2">
      <div class="card-body">
        <TimelinePreview :list="preset.audio" :data="TableauAudioData" :totalDuration="totalDuration" />
        <TimelinePreview :list="preset.scenes" :data="TableauSceneData" :totalDuration="totalDuration" class="my-1" />
        <div class="float-end text-muted"><b>Total Duration:</b> {{ totalDuration }} minute(s)</div>
      </div>
    </div>

    <div class="card mb-2">
      <div class="card-body">
        <h5>Audio</h5>
        <TimelineList v-model="preset.audio" @add="handleAddAudio" />

        <button type="button" class="btn btn-primary btn-sm mt-2" data-bs-toggle="modal"
          data-bs-target="#timelineAudioSelect">
          <fa icon="plus"></fa>
          Add Audio
        </button>
      </div>
    </div>

    <div class="card mb-2">
      <div class="card-body">
        <h5>Scenes</h5>
        <TimelineList v-model="preset.scenes" @add="handleAddScene" />

        <button type="button" class="btn btn-primary btn-sm mt-2 me-2" data-bs-toggle="modal"
          data-bs-target="#timelineSceneSelect">
          <fa icon="plus"></fa>
          Add Scene
        </button>
        <button type="button" class="btn btn-primary btn-sm mt-2" @click="autoSceneDuration">
          <fa icon="compress"></fa>
          Fit To Audio
        </button>
      </div>
    </div>

    <TimelineSceneSelect @add="handleAddScene" />
    <TimelineAudioSelect @add="handleAddAudio" />

    <div class="card">
      <div class="card-body">
        <div class="form-group">
          <input type="text" class="form-control" id="inputPresetName" placeholder="Enter Preset Name"
            v-model="preset.name" />
        </div>
        <button type="button" class="btn btn-primary btn-sm mt-2" @click="savePreset" :disabled="!validTimeline">
          <fa icon="file-export"></fa>
          Save Preset
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Ref } from 'vue';
import type { TimelineData, PresetData, SceneTemplateData, AudioTemplateData } from "../library/Constants";
import type Store from "../library/Store";

import TimelinePreview from "./TimelinePreview.vue";
import TimelineList from "./TimelineList.vue";

import TimelineSceneSelect from "./TimelineSceneSelect.vue";
import TimelineAudioSelect from "./TimelineAudioSelect.vue";

import { TableauAudioData, TableauSceneData } from "../library/Constants";

import { ref, computed, inject } from "vue";

const $store: Store | undefined = inject("$store");

const presetTemplate: PresetData = { name: "", audio: [], scenes: [] };
const preset: Ref<PresetData> = ref(presetTemplate);

// const presetName: Ref<string> = ref("");
// const timelineScenes: Ref<TimelineData[]> = ref([]);
// const timelineAudio: Ref<TimelineData[]> = ref([]);

const totalAudioDuration = computed(() => {
  let audioDuration = 0;
  preset.value.audio.forEach((audio: TimelineData) => {
    audioDuration += (audio.duration);
  });
  return audioDuration;
});

const totalSceneDuration = computed(() => {
  let sceneDuration = 0;
  preset.value.scenes.forEach((scene: TimelineData) => {
    sceneDuration += (scene.duration);
  });
  return sceneDuration;
});

const totalDuration = computed(() => {
  let duration = Math.max(totalSceneDuration.value, totalAudioDuration.value);
  return duration;
});

const validTimeline = computed(() => {
  return (
    totalSceneDuration.value > 0 &&
    totalAudioDuration.value > 0 &&
    preset.value.name != ""
  );
});

const savePreset = () => {
  let timelineAudioData = preset.value.audio.map(({ id, duration }) => ({
    id,
    duration,
  }));

  let timelineSceneData = preset.value.scenes.map(({ id, duration }) => ({
    id,
    duration,
  }));

  $store?.data.presets.push({
    name: preset.value.name,
    audio: timelineAudioData,
    scenes: timelineSceneData,
  } as PresetData);
};

const handleAddAudio = (audioData: TimelineData) => {
  preset.value.audio.push(audioData);
};

const handleAddScene = (sceneData: TimelineData) => {
  preset.value.scenes.push(sceneData);
};

const autoSceneDuration = () => {
  let duration = 0;

  preset.value.audio.forEach((audio: TimelineData) => {
    duration += audio.duration;
  });

  if (duration > 0) {
    duration /= preset.value.scenes.length;
    preset.value.scenes.forEach((scene: TimelineData) => {
      scene.duration = duration;
    });
  }
};
</script>